import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { getToken } from './utils/auth'

Vue.prototype.$bus = new Vue();

// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


// lucky
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

// 前置守卫
router.beforeEach((to, from, next) => {
  const token = getToken();
  if (!token && to.path !== '/login') {
    next('/login'); // 如果没有 token 且目标路径不是登录页面，则重定向到登录页面
  } else {
    next();
  }
});


Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
